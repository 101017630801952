import React from 'react'

import {Link} from 'gatsby'

import AdSense from "./adsense";
import BlogPostPreview from './blog-post-preview'
import Container from './container'

import * as styles from './blog-post-preview-list.module.css'


function BlogPostPreviewList (props) {

  var nodes = (props || {}).nodes
  var skip_missing_images = props.skip_missing_images || false
  var page_has_parent = props.page_has_parent || false
  
  // fitler to page_type
  if (props.page_type_name){
    nodes = nodes.filter( node => node.page_type.name===props.page_type_name)
  }

  // filter to the site_section
  if (props.site_section_name){
    nodes = nodes.filter( node => node.site_section.name===props.site_section_name)
  }

  // must have an image or it will get filtered
  if(skip_missing_images){
    nodes = nodes.filter( node => node.main_image )
  }
  
  // sort by page value
  //nodes.sort((a, b) => (a.page_value > b.page_value) ? -1 : 1)

  // filter to a max_n
  if (props.max_n){
    nodes = nodes
      .slice(0,props.max_n)
  }

  // array of arrays to regroup long list into smaller list
  var arrayOfnodes = [];
  // used to put ads inbetween every 'size'
  var size = 2;
  for (var i=0; i<nodes.length; i+=size) {
    arrayOfnodes.push(nodes.slice(i,i+size));
  }


  var nth_ad = 0;

  return (
    <>
      {props.title && <Container><h2 className={styles.title}>{props.title}</h2></Container>}
      <>
        {arrayOfnodes && arrayOfnodes.map((nodes,index_nodes) => (
          <div key={`${index_nodes}`}>
            <div key={`div_${index_nodes}`}>
              <ul className={styles.grid} key={`set_${index_nodes}`}>
                {nodes.map((node,index) => (
                  <li key={`${node.id}_${node.slug}`}>
                    <BlogPostPreview {...node} target_width={props.target_width} isInList page_has_parent={page_has_parent}/>
                  </li>
                ))}
              </ul>
            </div>
            {process.env.EZOIC_ACCOUNT_ID && props.ad_ids &&
            <div className={styles.sectionFullwidth} key={`ad_${index_nodes}`}>
              <div dangerouslySetInnerHTML={{ __html: `
                            <div id="ezoic-pub-ad-placeholder-`+props.ad_ids[nth_ad = nth_ad + 1]+`"> </div>`}}/>
            </div>
            }
            {process.env.ADSENSE_CLIENT_ID && props.ad_ids &&
                <div className={styles.sectionFullwidth} key={`ad_${index_nodes}`}>
                    <AdSense id={props.ad_ids[nth_ad = nth_ad + 1]} style="inline1" layout="-fc+5g+70-cl-1m"/>
                </div>
            }
              

          </div>
        ))}
      </>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </>
  )
}

BlogPostPreviewList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '',
  page_has_parent: false
}

export default BlogPostPreviewList
