import React from 'react'

export default function AdSense( {id, style, layout} ) {
    React.useEffect(() => {
      if (process.env.NODE_ENV !== "development") {
        if (window) {
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          } catch (error) {
            console.log(error, "adsenese error");
          }
        }
      }
    }, []);
  
    if (style=="inline1"){
        return (
            <ins
                className="adsbygoogle"
                data-ad-client={process.env.ADSENSE_CLIENT_ID || "ca-pub-XXXXXX"}
                data-ad-slot= {id || "XXXXXXX"}
                style={{
                    display: "block"
                }}
                data-ad-format="fluid"
                data-ad-layout-key= {layout || "-fc+5g+70-cl-1m"}
            ></ins>
        )
    } else if ( style=="fullwidth1"){
        return (
            <ins
                className="adsbygoogle"
                data-ad-client={process.env.ADSENSE_CLIENT_ID || "ca-pub-XXXXXX"}
                data-ad-slot= {id || "XXXXXXX"}
                style={{
                    display: "block"
                }}
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        )
    } else {
        return (
            <ins
                className="adsbygoogle"
                data-ad-client={process.env.ADSENSE_CLIENT_ID || "ca-pub-XXXXXX"}
                data-ad-slot= {id || "XXXXXXX"}
                style={{
                    display: "inline-block",
                    width: "300px",
                    height: "250px"
                }}
            ></ins>
        );

    }
  }
