import React from "react"

import {graphql, Link, useStaticQuery} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {getPageUrl} from '../lib/helpers'

import Layout from '../containers/layout'

// relatedPages is out of order due to where it loads from templates/page->blog-post.js
import RelatedPages from '../components/related-pages'

import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import HomepageBottom from "../components/homepage-bottom"
import HomepageHero from '../components/homepage-hero'
import SearchBar from '../components/search-bar'
import MetaSchemaSEO from '../components/seo';

import * as styles from './index.module.css'

export const query = graphql`
  query {
    strapiSiteSetting {
      id
      site_name
      description: default_seo_description
    }
    strapiHomepage {
      hero_page {
        slug
        title
        primary_topic
        page_type {
          name
          slug_prefix
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 1000,
                aspectRatio: 1.8,
                quality: 70,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
      featured_pages {
        id
        title
        slug
        excerpt
        main_image {
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 200,
                aspectRatio: 0.75,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }


    news: allStrapiPage(
      filter: {main_image: {id: {ne: null}}}
      sort: {fields: createdAt, order: DESC}
      limit: 5
      ) {
      nodes {
        id
        is_featured
        slug
        title
        excerpt
        primary_topic
        page_type {
          name
          slug_prefix
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 300,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }

    recent: allStrapiPage(
      sort: {fields: updatedAt, order: DESC}
      limit: 21
      ) {
      nodes {
        id
        is_featured
        slug
        title
        excerpt
        primary_topic
        page_type {
          name
          slug_prefix
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 300,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }
  }
`

// markup
const IndexPage = () => {
  const data = useStaticQuery(query);

  const heroPage = data?.strapiHomepage?.hero_page || {}
  const postNodes = data?.news.nodes
  const recentPages = data?.recent.nodes
  const featuredSitePages = data?.strapiHomepage?.featured_pages


  return (
    <Layout>
      <MetaSchemaSEO/>
      <HomepageHero></HomepageHero>
      <Container>
          <div className={styles.hero}>
            {heroPage &&
              <Link to={getPageUrl(heroPage)} key="hero_page">
                <h2>{heroPage.title}</h2>
                { heroPage.main_image && 
                <GatsbyImage 
                  className={styles.heroImage} 
                  image={getImage(heroPage.main_image.localFile)} 
                  alt={heroPage.title} />
                }
              </Link>
            }
          </div>
        { featuredSitePages && (
          <div className={styles.featuredPages}>
            <ul className={styles.grid_two}>
              <li>

                <BlogPostPreviewList
                  nodes={featuredSitePages.slice(0,1)}
                />
              </li>
              <li>

                <BlogPostPreviewList
                  nodes={featuredSitePages.slice(1,2)}
                />
              </li>
            </ul>
          </div>
        )
        }
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }

      {postNodes && (
        <>
          <BlogPostPreviewList
            nodes={postNodes}
          />
        </>
      )}
        </Container>
      <Container>
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }

      </Container>

      {featuredSitePages && featuredSitePages.length > 0 && (<RelatedPages nodes={featuredSitePages}></RelatedPages>)}
      <Container>
        {recentPages && recentPages.length > 0 &&
          <div className={styles.latestPages}>
            <span>Recently Published</span>
            <ul className={styles.listPages}>
                {recentPages.slice(0,20).map(post => (
                    <li key={post.id}>
                      <Link to={getPageUrl(post)}>{post.title}</Link>
                    </li>
                ))}
            </ul>
            { recentPages.length > 20 && 
            <div className={styles.morePages}>
              <Link to={'/page/1'}>More recently published pages</Link>
            </div>
            }
          </div>
        }
      </Container>
      <HomepageBottom></HomepageBottom>
    </Layout>
  )
}

export default IndexPage
